import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SectionLayout from '@components/v5/layouts/SectionLayout/index';
import Carousel from './Carousel/index';
import twConfig from '../../../../../utils/tailwind-fullConfig';
import { useInView } from 'react-intersection-observer';
import { RoughNotation } from 'react-rough-notation';

const LaunchRecruiter = React.memo(function LaunchRecruiter(props) {
  const [ref, inView] = useInView();

  return (
    <SectionLayout
      {...props}
      heading={
        <>
          <Heading></Heading>
          <span id="personalised-profile"></span>
        </>
      }
      description={
        <p className="font-normal text-launch-neutral-100">
          Showcase your competencies, tech skills, and externship project work{' '}
          <br className="hidden sm:block"></br>
          to prospective recruiters with{' '}
          <strong className="font-bold text-launch-green-100">
            your very own Crio Launch
          </strong>{' '}
          profile
        </p>
      }
      className="bg-launch-blue-400 text-launch-neutral-100"
    >
      <div className="container">
        <Carousel data={data}></Carousel>
      </div>
    </SectionLayout>
  );
});

const Heading = () => {
  const [ref, inView] = useInView();

  return (
    <div ref={ref}>
      Personalised{' '}
      <RoughNotation
        type="underline"
        show={inView}
        color={twConfig.theme.colors['launch']['green']['100']}
        multiline={true}
        animationDuration={1500}
        strokeWidth={6}
      >
        <span className="text-launch-green-100">Crio Launch</span>
      </RoughNotation>{' '}
      <br className="hidden sm:block"></br>profile that recruiters trust
    </div>
  );
};

export default LaunchRecruiter;

const data = [
  {
    title: (
      <>
        Developer <br className="hidden sm:block"></br>competencies
      </>
    ),
    description: (
      <>
        Showcase your level of expertise <br className="hidden sm:block"></br>
        across competencies that tech <br className="hidden sm:block"></br>
        recruiters look for in their candidates
      </>
    ),
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Profile Carousel/Scores.png"
        placeholder="blurred"
        alt="Scores"
        width={1500}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: <>Skills</>,
    description: (
      <>
        Highlight all the critical tech skills that{' '}
        <br className="hidden sm:block"></br>you put to use to build the
        externship <br className="hidden sm:block"></br>project
      </>
    ),
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Profile Carousel/Skills.png"
        placeholder="blurred"
        alt="Skills"
        width={1500}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: (
      <>
        Tech <br className="hidden sm:block"></br>Projects
      </>
    ),
    description: (
      <>
        Share a detailed overview of the mini-
        <br className="hidden sm:block"></br>project and externship project you{' '}
        <br className="hidden sm:block"></br>
        worked on in Crio Launch
      </>
    ),
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Profile Carousel/Projects.png"
        placeholder="blurred"
        alt="Projects"
        width={1500}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
];
