import React from 'react';
import SectionLayout from '@components/v5/layouts/SectionLayout/index';
import { StaticImage } from 'gatsby-plugin-image';

export default function LaunchExternship({ postContent }) {
  return (
    <SectionLayout className=" bg-launch-blue-400 text-launch-neutral-100">
      <div className=" container">
        <div className="flex flex-col flex-wrap items-center  justify-center gap-x-14 gap-y-5 text-center sm:flex-row sm:flex-nowrap sm:justify-start sm:text-left">
          <div className="min-w-[200px] max-w-[200px] sm:min-w-[400px] sm:max-w-[400px]">
            <StaticImage
              width={600}
              loading="lazy"
              src="../../../../images/v5/Launch/Externship.png"
              placeholder="blurred"
              alt="Externship"
            />
          </div>
          <div>
            <span id="what-is-an-externship"></span>
            <h1 className="mb-4 font-manrope capitalize">
              What is an externship?
            </h1>

            <p className="leading-loose sm:max-w-[75%]">
              Externships are experiential, project-building opportunities,
              similar to internships, but shorter and part-time. Crio
              externships are a great opportunity for you to{' '}
              <strong className="font-bold text-launch-green-100">
                solve real-world problems in a setting similar to a tech startup
              </strong>
              , and showcase skills that will help you advance your dev career.
            </p>
            {postContent}
          </div>
        </div>
      </div>
    </SectionLayout>
  );
}
