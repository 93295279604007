import React from 'react';
import SectionLayout from '@components/v5/layouts/SectionLayout/index';
import { EProgramIDs } from '@src/constants/data/programs/index';

import TestimonialSlider from '@components/TestimonialSlider/index';
import useResizer from '@components/extra/useResizer';

export default function LaunchTestimonial({
  testimonials,
  companyImagesData,
  testimonialImagesData,
  ...props
}) {
  const isMobile = useResizer();

  return (
    <SectionLayout
      heading={
        <>
          Join our community of developers
          <br className="hidden md:block" /> who have reached new career heights
        </>
      }
      className="flex  flex-col items-center bg-launch-blue-400 pb-[400px] text-launch-neutral-100"
      {...props}
    >
      <div className=" w-full md:w-4/5 md:max-w-[1100px]">
        <TestimonialSlider
          testimonials={testimonials}
          companyImagesData={companyImagesData}
          testimonialImagesData={testimonialImagesData}
          variant={EProgramIDs.LAUNCH}
        />
      </div>
      {/* <div
        className="z-[1] h-[200px] w-screen  md:h-[300px]"
        style={
          isMobile
            ? {}
            : {
                backgroundImage: `url(${sectionBackground})`,
                backgroundSize: '60rem',
              }
        }
      ></div> */}
    </SectionLayout>
  );
}
