import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SectionLayout from '@components/v5/layouts/SectionLayout/index';
import Carousel from './Carousel/index';

const LaunchExternshipProjects = React.memo(function LaunchExternshipProjects(
  props,
) {
  return (
    <SectionLayout
      {...props}
      heading={'Past Externship Projects'}
      description={
        <p className="font-normal text-launch-neutral-100">
          Externship projects are inspired by or developed in direct
          collaboration with some of the hottest tech organizations and startups
          in the country. Here are some of the past projects{' '}
          <strong className="font-bold text-launch-green-100">
            Crio Launch
          </strong>{' '}
          developers worked on,
        </p>
      }
      className="bg-launch-blue-400 text-launch-neutral-100"
    >
      <div className="container">
        <Carousel data={data}></Carousel>
      </div>
    </SectionLayout>
  );
});

export default LaunchExternshipProjects;

const data = [
  {
    title: (
      <>
        Credit card <br className=""></br>management system
      </>
    ),
    label: 'Unicorn',
    image: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Company Logos/Externship Companies/CRED.png"
        placeholder="blurred"
        alt="CRED"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    projectImage: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Externship Cards/Cred.png"
        placeholder="blurred"
        alt="CRED"
        width={250}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: (
      <>
        GPS-based asset <br className=""></br>tracking portal
      </>
    ),
    image: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Company Logos/Externship Companies/Jumbotail.png"
        placeholder="blurred"
        alt="Jumbotail"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    projectImage: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Externship Cards/Jumbotail.png"
        placeholder="blurred"
        alt="Jumbotail"
        width={250}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: (
      <>
        Task orchestrator & <br className=""></br>scheduler library
      </>
    ),
    image: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Company Logos/Externship Companies/AWS.png"
        placeholder="blurred"
        alt="AWS"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    projectImage: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Externship Cards/AWS.png"
        placeholder="blurred"
        alt="AWS"
        width={250}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: (
      <>
        Contextual chatbot <br className=""></br>for investors
      </>
    ),
    label: 'Unicorn',
    image: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Company Logos/Externship Companies/Groww.png"
        placeholder="blurred"
        alt="Groww"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    projectImage: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Externship Cards/Groww.png"
        placeholder="blurred"
        alt="Groww"
        width={250}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: (
      <>
        Accounting integration <br className=""></br>service
      </>
    ),
    label: 'Acquired by Stripe',

    image: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Company Logos/Externship Companies/Recko.png"
        placeholder="blurred"
        alt="Recko"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    projectImage: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Externship Cards/Recko 1.png"
        placeholder="blurred"
        alt="Recko"
        width={250}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: (
      <>
        Doc library and <br className=""></br>digi-locker integration
      </>
    ),
    label: 'Unicorn',

    image: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Company Logos/Externship Companies/Slice.png"
        placeholder="blurred"
        alt="Slice"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    projectImage: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Externship Cards/SliceImage.png"
        placeholder="blurred"
        alt="Slice"
        width={250}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },

  {
    title: (
      <>
        Storage drive <br className=""></br>for enterprises
      </>
    ),
    image: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Company Logos/Externship Companies/Vicara.png"
        placeholder="blurred"
        alt="Vicara"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    projectImage: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Externship Cards/Vicara.png"
        placeholder="blurred"
        alt="Vicara"
        width={250}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
  {
    title: (
      <>
        Cash flow <br className=""></br>management app
      </>
    ),
    image: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Company Logos/Externship Companies/Jumbotail.png"
        placeholder="blurred"
        alt="Jumbotail"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    projectImage: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Externship Cards/Jumbotail 2.png"
        placeholder="blurred"
        alt="Jumbotail"
        width={250}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },

  {
    title: (
      <>
        OAuth <br className=""></br>manager
      </>
    ),
    label: 'Acquired by Stripe',
    image: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Company Logos/Externship Companies/Recko.png"
        placeholder="blurred"
        alt="Recko"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    projectImage: (
      <StaticImage
        loading="lazy"
        src="../../../../images/v5/Launch/Externship Cards/Recko 2.png"
        placeholder="blurred"
        alt="Recko"
        width={250}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
  },
];
