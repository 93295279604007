import CrioButton from '@components/v5/CrioButton/index';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function LaunchCTA({ className, ...props }) {
  return (
    <CrioButton
      variant="primary"
      {...props}
      className={twMerge(
        `h-[60px] px-4`,
        `${className} rounded-[5px] bg-launch-green-100 shadow-none hover:bg-launch-green-100 hover:bg-opacity-75 hover:shadow-none`,
      )}
    >
      <div className="pr-2 text-left">
        <h6 className="font-manrope ">Launch your career</h6>
      </div>
    </CrioButton>
  );
}
