import React from 'react';
import SectionLayout from '@components/v5/layouts/SectionLayout/index';
import twConfig from '../../../../../utils/tailwind-fullConfig';
import { useInView } from 'react-intersection-observer';
import { RoughNotation } from 'react-rough-notation';

export default function LaunchCard() {
  const [ref, inView] = useInView();
  return (
    <SectionLayout className=" bg-launch-blue-400 text-launch-neutral-100">
      <div className=" container">
        <div
          className="relative overflow-hidden rounded-[30px] bg-launch-blue-900 text-center sm:rounded-[50px]"
          style={{
            boxShadow: `10px 10px 100px -20px ${twConfig.theme.colors['launch']['blue']['800']}`,
          }}
        >
          <div className="p-5 py-14 font-manrope" ref={ref}>
            <h2 className="mb-6 font-semibold leading-relaxed sm:mb-6 sm:leading-tight">
              You pay nothing to become <br className="hidden sm:block"></br>a{' '}
              <RoughNotation
                type="underline"
                show={inView}
                color={twConfig.theme.colors['launch']['green']['100']}
                multiline={true}
                animationDuration={1500}
                strokeWidth={6}
              >
                <span className="font-bold text-launch-green-100">
                  Crio Launch
                </span>
              </RoughNotation>{' '}
              developer.
            </h2>

            <p className="font-rubik font-light">
              Your effort is the only investment!
            </p>
          </div>
          <div className="relative flex flex-col items-center justify-center rounded-t-[100px] bg-launch-blue-600 p-5 py-14 text-center sm:rounded-t-[160px]">
            <p className="mb-4">Designed for</p>
            <div className="mb-4 w-full rounded-[100px]  border-[3px] border-launch-neutral-300 px-10 py-1 xxs:w-10/12 sm:w-1/2">
              <h6>
                Student developers in <br className="sm:hidden"></br>their final
                year of college
              </h6>
            </div>{' '}
            <div className="w-full rounded-[100px]  border-[3px] border-launch-neutral-300 px-10 py-1 xxs:w-10/12 sm:w-1/2">
              <h6>
                Working professionals with <br className="sm:hidden"></br>up to
                3 years of experience
              </h6>
            </div>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
}
