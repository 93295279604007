import React from 'react';
import FAQSection from '@components/v5/FAQSection/index';
import { EProgramIDs } from '@src/constants/data/programs/index';

export default function LaunchFAQ({ preContent, faqs, ...props }) {
  //   console.log('debug > ', faqs);
  return (
    <div className="min-h-[800px] rounded-t-[60px] bg-white py-10 pt-8 sm:rounded-t-[100px] md:pt-10">
      {preContent}
      <FAQSection
        data={faqs}
        {...props}
        preContent={<></>}
        variant={EProgramIDs.LAUNCH}
      ></FAQSection>
    </div>
  );
}
