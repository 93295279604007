import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import CrioButton from '../CrioButton/index';
import { EProgramIDs } from '@src/constants/data/programs/index';

export default function ProgramCardEmailPopup({
  dark,
  onClose,
  onSubmit,
  variant,
}) {
  return (
    <div
      className={`${
        !dark ? 'bg-white text-black ' : 'bg-v5-green-500 text-white'
      } relative w-full rounded-[20px] border border-gray-300 p-3 `}
      //   style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.16)' }}
    >
      <div
        className=" absolute right-3 top-0 z-10 cursor-pointer p-2"
        onClick={onClose}
      >
        <FontAwesomeIcon
          icon={['fas', 'times']}
          className="opacity-20"
        ></FontAwesomeIcon>
      </div>

      <form
        className="mt-2 w-full"
        onSubmit={(e) => {
          e.preventDefault();
          let email = e.target.email.value;
          onSubmit(email);
        }}
      >
        <FormControl className="w-full">
          <InputBase
            sx={{ p: 1 }}
            inputProps={{ 'aria-label': 'Apply to Crio' }}
            color="yellow"
            size="normal"
            className={`rounded-[20px]bg-white mb-4 w-full ${
              !dark
                ? 'border-gray-300 text-black'
                : 'border-gray-300 text-white'
            }    flex-1  border-b`}
            id="filled-basic"
            autoFocus
            placeholder="Email"
            type="email"
            name="email"
            required
          />
          <div className="flex flex-nowrap items-center gap-x-2">
            <CrioButton
              variant="secondary"
              className={`w-full ${dark ? 'border-white text-white' : ''} ${
                variant === EProgramIDs.LAUNCH
                  ? 'hover:border-launch-blueFSP-500 hover:bg-launch-blueFSP-200 hover:text-launch-blueFSP-500'
                  : ''
              }`}
              onClick={onClose}
            >
              Close
            </CrioButton>

            <CrioButton
              type="submit"
              variant={
                variant === EProgramIDs.LAUNCH ? EProgramIDs.LAUNCH : 'primary'
              }
              className="w-full bg-launch-blueFSP-500 text-white hover:text-launch-blueFSP-500"
            >
              Apply Now
            </CrioButton>
          </div>
        </FormControl>
      </form>
    </div>
  );
}
