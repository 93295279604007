import React, { useContext, useState } from 'react';
import ProgramCardPill from '../ProgramCardPill/index';
import { StaticImage } from 'gatsby-plugin-image';
import CrioButton from '../CrioButton/index';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import ProgramCardEmailPopup from '../ProgramCardEmailPopup/index';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import { GTM } from '@src/analytics/gtm';
import { navigate } from 'gatsby';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { FellowshipProgramCardFooter } from '../Sale/Homepage/index';
import { EProgramIDs } from '@src/constants/data/programs/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_PROGRAM_CARD,
  LEAD_GEN_PROGRAM_ID_QA,
  LEAD_GEN_PROGRAM_ID_FULL_STACK,
} from '../../../constants/LeadGenerationConstants/index';

export default function FellowshipProgramCard({
  strapiProgram,
  program,
  icon,
  route,
  sale,
  variant,
  location,
}) {
  const [open, setOpen] = useState(false);

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const onSubmit = (email, program) => {
    let emailValue = email;
    dispatch({
      type: 'SET_EMAIL',
      payload: emailValue,
    });

    GTM.login({
      userId: emailValue,
      email: emailValue,
    });
    GTM.track(gtmEvents.EMAIL_SUBMITTED, {
      type: program.PrimaryProgramID,
      location: location || 'HOME_PROGRAM_CARD',
    });
    if (process.env.GATSBY_ENV === 'production') {
      fetch(
        `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&email=${emailValue}&country=${
          state.country
        }&type=${program.PrimaryProgramID}&location=${
          location || 'HOME_PROGRAM_CARD'
        }&utm=${encodeURIComponent(state.utm)}&adblocker=${
          typeof window.webengage == 'undefined' ? true : false
        }&url=${window.location.href}`,
      );
    }
    let typeform = program.typeform;
    if (
      state.registrationDetails &&
      state.registrationDetails[program.PrimaryProgramID]
    ) {
      navigate(
        `/registered/?id=${
          state.registrationDetails[program.PrimaryProgramID].id
        }`,
      );
    } else {
      navigate('/registration', {
        state: {
          link: typeform,
          utm: state.utm,
          type: program.PrimaryProgramID,
          location: location || 'HOME_PROGRAM_CARD',
          program: program,
        },
      });
    }
  };

  const getProgramCardFooter = (sale, program) => {
    switch (sale) {
      case 'true':
        return <FellowshipProgramCardFooter program={program} />;
      default:
        return <></>;
    }
  };

  return (
    <div className=" relative w-full ">
      <div
        className={`" ${
          sale === 'true'
            ? 'rounded-t-[20px] sm:rounded-t-[50px] '
            : 'rounded-[20px] sm:rounded-[50px]'
        } overflow-hidden ${
          variant === EProgramIDs.LAUNCH
            ? 'bg-launch-blueFSP-500'
            : 'bg-v5-green-500'
        } text-v5-neutral-200 `}
      >
        <div className="flex flex-wrap justify-between gap-x-6 gap-y-6 p-3 pb-5 sm:flex-nowrap sm:p-6">
          <div className="flex flex-col gap-y-2 text-center sm:w-3/12 sm:text-left">
            <div
              className={`mb-2 hidden w-[78px] sm:block ${
                open ? 'blur-sm' : ''
              } `}
            >
              {icon}
            </div>
            <div
              className={`absolute left-0 right-0 mx-auto -mt-[50px] mb-2 w-[78px] sm:hidden ${
                open ? 'blur-sm' : ''
              }`}
            >
              {icon}
            </div>
            <h5
              className={`pt-10 font-manrope  font-extrabold ${
                variant === EProgramIDs.LAUNCH
                  ? 'text-launch-blueFSP-200'
                  : 'text-v5-green-100'
              } sm:pt-0 ${open ? 'blur-sm' : ''}`}
            >
              {program.title}
            </h5>
            <p className={` leading-normal ${open ? 'blur-sm' : ''}`}>
              {program.description}
            </p>
          </div>
          <div
            className={` hidden flex-col gap-y-2 whitespace-nowrap sm:flex sm:w-4/12 ${
              open ? 'blur-sm' : ''
            }`}
          >
            <ProgramCardPill
              dark
              icon={
                <StaticImage
                  width={50}
                  loading="lazy"
                  src="../../../images/v5/ProgramCards/Fellowship_QA Card Icons/FreeTrial.png"
                  placeholder="blurred"
                  alt="Trial Session"
                  imgStyle={{ objectFit: 'contain' }}
                />
              }
              title="Trial Session"
              description="Free"
              variant={variant}
            ></ProgramCardPill>
            <ProgramCardPill
              dark
              icon={
                <StaticImage
                  width={50}
                  loading="lazy"
                  src="../../../images/v5/ProgramCards/Fellowship_QA Card Icons/Duration.png"
                  placeholder="blurred"
                  alt="Duration"
                  imgStyle={{ objectFit: 'contain' }}
                />
              }
              title="Duration"
              description={program.duration}
              variant={variant}
            ></ProgramCardPill>
            <ProgramCardPill
              dark
              icon={
                <StaticImage
                  width={50}
                  loading="lazy"
                  src="../../../images/v5/ProgramCards/Fellowship_QA Card Icons/Scholarship.png"
                  placeholder="blurred"
                  alt="Trial Session"
                  imgStyle={{ objectFit: 'contain' }}
                />
              }
              title="Scholarships"
              description="Assured scholarships"
              variant={variant}
            ></ProgramCardPill>
            <ProgramCardPill
              dark
              icon={
                <StaticImage
                  width={50}
                  loading="lazy"
                  src="../../../images/v5/ProgramCards/Fellowship_QA Card Icons/Career.png"
                  placeholder="blurred"
                  alt="Trial Session"
                  imgStyle={{ objectFit: 'contain' }}
                />
              }
              title="Career Services"
              description="Assured Referrals"
              special
              variant={variant}
            ></ProgramCardPill>
          </div>
          <div
            className={`${open ? 'blur-sm' : ''} flex w-full gap-x-2 sm:hidden`}
          >
            <div className="flex w-[32%] flex-col gap-y-2 ">
              <ProgramCardPill
                dark
                icon={
                  <StaticImage
                    width={50}
                    loading="lazy"
                    src="../../../images/v5/ProgramCards/Fellowship_QA Card Icons/FreeTrial.png"
                    placeholder="blurred"
                    alt="Trial Session"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                }
                title="Trial Session"
                description="Free"
                variant={variant}
              ></ProgramCardPill>
              <ProgramCardPill
                dark
                icon={
                  <StaticImage
                    width={50}
                    loading="lazy"
                    src="../../../images/v5/ProgramCards/Fellowship_QA Card Icons/Duration.png"
                    placeholder="blurred"
                    alt="Duration"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                }
                title="Duration"
                description={program.duration}
                variant={variant}
              ></ProgramCardPill>
            </div>
            <div className="flex w-[68%] flex-col gap-y-2">
              <ProgramCardPill
                dark
                icon={
                  <StaticImage
                    width={50}
                    loading="lazy"
                    src="../../../images/v5/ProgramCards/Fellowship_QA Card Icons/Scholarship.png"
                    placeholder="blurred"
                    alt="Trial Session"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                }
                title="Scholarships"
                description="Assured scholarships"
                variant={variant}
              ></ProgramCardPill>
              <ProgramCardPill
                dark
                icon={
                  <StaticImage
                    width={50}
                    loading="lazy"
                    src="../../../images/v5/ProgramCards/Fellowship_QA Card Icons/Career.png"
                    placeholder="blurred"
                    alt="Trial Session"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                }
                title="Career Services"
                description="Assured Referrals"
                special
                variant={variant}
              ></ProgramCardPill>
            </div>
          </div>

          <div
            className={`relative  flex  w-full    flex-col gap-y-3 sm:w-5/12 `}
          >
            <div
              className={`flex flex-col gap-y-6 rounded-[20px]    ${
                variant === EProgramIDs.LAUNCH
                  ? 'bg-launch-blueFSP-400'
                  : 'bg-v5-green-700'
              } p-3 sm:p-7 ${open ? 'blur-sm' : ''}`}
            >
              {program.highlights.map((ele, idx) => (
                <div className="" key={idx}>
                  <div className="flex items-start gap-x-4 text-sm ">
                    <div className="min-w-[20px]">
                      <div className="w-[20px] ">
                        <StaticImage
                          width={40}
                          loading="lazy"
                          src="../../../images/v5/ProgramCards/LaunchTick.png"
                          placeholder="none"
                          alt="Tick"
                          imgStyle={{ objectFit: 'contain' }}
                        />
                      </div>
                    </div>
                    <p>{ele.title}</p>
                  </div>
                </div>
              ))}
            </div>
            <AnimatePresence>
              {open ? (
                <div className="w-full ">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                      duration: 0.3,
                      type: 'tween',
                      stiffness: 100,
                    }}
                    className="w-full"
                    exit={{ opacity: 0 }}
                  >
                    <div className="absolute bottom-0 left-0 w-full">
                      <ProgramCardEmailPopup
                        onClose={() => {
                          dispatch({
                            type: 'USER_IS_APPLYING',
                            payload: false,
                          });
                          setOpen(false);
                        }}
                        onSubmit={(e) => {
                          onSubmit(e, strapiProgram);
                        }}
                        variant={variant}
                      ></ProgramCardEmailPopup>
                    </div>
                  </motion.div>
                  <div className="invisible h-[55px]"></div>
                </div>
              ) : (
                <div className="">
                  <div className="flex flex-nowrap items-center gap-x-2">
                    <Link to={route} className="w-full">
                      <CrioButton
                        variant="secondary"
                        className={`w-full border-white text-white ${
                          variant === EProgramIDs.LAUNCH
                            ? 'hover:border-launch-blueFSP-500 hover:bg-launch-blueFSP-200 hover:text-launch-blueFSP-500'
                            : ''
                        }`}
                      >
                        Learn More
                      </CrioButton>
                    </Link>
                    <div className="w-full">
                      <LeadGenerationButton
                        text="Apply Now"
                        buttonLocation={LEAD_GEN_PROGRAM_CARD}
                        programType={
                          route === '/qa-automation-testing'
                            ? LEAD_GEN_PROGRAM_ID_QA
                            : LEAD_GEN_PROGRAM_ID_FULL_STACK
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      {getProgramCardFooter(sale, strapiProgram.PrimaryProgramID)}
    </div>
  );
}
