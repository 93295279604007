import { EProgramIDs } from '@src/constants/data/programs/index';
import React from 'react';

export default function ProgramCardPill({
  icon,
  title,
  description,
  dark,
  special,
  variant,
}) {
  const getBackgroundColor = (variant, special, dark) => {
    if (variant === EProgramIDs.LAUNCH) {
      return special
        ? 'bg-launch-blueFSP-100'
        : !dark
        ? 'bg-white'
        : 'bg-launch-blueFSP-300';
    }

    return special ? 'bg-v5-green-100' : !dark ? 'bg-white' : 'bg-v5-green-400';
  };

  return (
    <div
      className={`w-full text-left ${getBackgroundColor(
        variant,
        special,
        dark,
      )} ${
        special ? 'border-4 border-white' : 'border-4 border-transparent'
      } flex h-[70px] items-center gap-x-2 gap-y-2  rounded-[10px] py-1 px-1 text-sm leading-normal xxs:px-2 sm:h-full sm:gap-x-3`}
    >
      <div className="w-[18px] sm:w-[24px]"> {icon}</div>
      <div>
        <h6
          className={`text-sm  font-normal ${
            special
              ? 'text-[#525252]'
              : !dark
              ? 'text-v5-neutral-400'
              : 'text-v5-neutral-300'
          } `}
        >
          {title}
        </h6>
        <p
          className={`text-xs  font-semibold xxs:text-sm ${
            special ? 'text-black' : !dark ? 'text-black' : 'text-white'
          } `}
        >
          {description}
        </p>
      </div>
    </div>
  );
}
