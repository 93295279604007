import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/Common/SEO/index';
import RoundedLayout from '@components/v5/layouts/RoundedLayout/index';
import FellowshipProgramCard from '@components/v5/FellowshipProgramCard/index';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import LaunchHero from '@components/v5/launch/LaunchHero/index';
import { StaticImage } from 'gatsby-plugin-image';
import { ERoutes } from '@src/constants/routes/index';
import { EProgramIDs, EPrograms } from '@src/constants/data/programs/index';
import LaunchFooter from '@components/v5/launch/LaunchFooter/index';
import LaunchFAQ from '@components/v5/launch/LaunchFAQ/index';
import LaunchHiringCompanies from '@components/v5/launch/LaunchHiringCompanies/index';
import LaunchTestimonial from '@components/v5/launch/LaunchTestimonial/index';
import SectionLayout from '@components/v5/layouts/SectionLayout/index';
import twConfig from '../../utils/tailwind-fullConfig';
import LaunchSteps from '@components/v5/launch/LaunchSteps/index';
import LaunchCard from '@components/v5/launch/LaunchCard/index';
import LaunchExternship from '@components/v5/launch/LaunchExternship/index';
import LaunchStructure from '@components/v5/launch/LaunchStructure/index';
import LaunchExternshipProjects from '@components/v5/launch/LaunchExternshipProjects/index';
import LaunchRecruiter from '@components/v5/launch/LaunchRecruiter/index';
import ApplyNowCTAV3 from '@components/Common/ApplyNowCTAV3/index';
import LaunchCTA from '@components/v5/launch/LaunchCTA/index';
import ScrollCTA from '@components/ScrollCTA/index';

export default function Launch({ data: queryData }) {
  const { launchPageYaml: data } = queryData;

  const Testimonials = data.testimonials;

  const CompanyImagesData = queryData.CompanyImages.nodes;
  const TestimonialImagesData = queryData.TestimonialImages.nodes;

  const links = data.footerLinks;
  const program = data;
  const location = useLocation();

  // redirect launch page until its in use again
  // useEffect(() => {
  //   if (window) {
  //     window.location.replace(
  //       '/software-development-fellowship-program/' + location?.search,
  //     );
  //   }
  // }, []);

  // return (
  //   <div className="flex h-screen w-screen items-center justify-center">
  //     <Loader></Loader>
  //   </div>
  // );

  return (
    <>
      <div>
        <Helmet
          meta={[
            {
              name: `theme-color`,
              content: twConfig.theme.colors['launch']['blue']['600'],
            },
          ]}
        ></Helmet>
        <SEO
          title={'Crio Launch - Tech Externships for Devs'}
          description={
            'Prove your developer skills in India’s largest tech externship opportunity for free and get hired into top companies. Crack a coding assessment, build a project, & get hired.'
          }
        />{' '}
        <ScrollCTA
          startAt={400}
          type={data.PrimaryProgramID}
          link={data.typeform}
          location="FLOATING_CTA"
          openEvent="PROGRAM_ME_APPLY_NOW_CLICKED"
          closeEvent="PROGRAM_ME_EMAIL_FORM_CLOSED"
        >
          <LaunchCTA className="w-full"></LaunchCTA>
        </ScrollCTA>
        <LaunchHero
          postContent={
            <>
              <div className="mb-4 flex justify-start">
                <ApplyNowCTAV3
                  as={<LaunchCTA></LaunchCTA>}
                  height="60px"
                  type={program.PrimaryProgramID}
                  meId={program.PrimaryProgramID}
                  innerText="Apply Now"
                  link={program.typeform}
                  location="HERO"
                  inputClassName="rounded-l-[5px]"
                  applyNowInsideClassName="bg-launch-green-100 hover:shadow-none  py-3 rounded-r-[5px]"
                  openEvent="FREE_ME_START_NOW_CLICKED"
                  closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                ></ApplyNowCTAV3>
              </div>
            </>
          }
        ></LaunchHero>
        <LaunchHiringCompanies
          postContent={
            <>
              <div className="my-4 mt-8 flex justify-center">
                <ApplyNowCTAV3
                  as={<LaunchCTA></LaunchCTA>}
                  height="60px"
                  type={program.PrimaryProgramID}
                  meId={program.PrimaryProgramID}
                  innerText="Apply Now"
                  link={program.typeform}
                  location="HIRING_COMPANIES"
                  inputClassName="rounded-l-[5px]"
                  applyNowInsideClassName="bg-launch-green-100 hover:shadow-none py-3 rounded-r-[5px]"
                  openEvent="FREE_ME_START_NOW_CLICKED"
                  closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                ></ApplyNowCTAV3>
              </div>
            </>
          }
        ></LaunchHiringCompanies>
        <LaunchSteps
          postContent={
            <>
              <div className="mt-16 flex justify-center">
                <ApplyNowCTAV3
                  as={<LaunchCTA></LaunchCTA>}
                  height="60px"
                  type={program.PrimaryProgramID}
                  meId={program.PrimaryProgramID}
                  innerText="Apply Now"
                  link={program.typeform}
                  location="STEPS"
                  inputClassName="rounded-l-[5px]"
                  applyNowInsideClassName="bg-launch-green-100 hover:shadow-none py-3 rounded-r-[5px]"
                  openEvent="FREE_ME_START_NOW_CLICKED"
                  closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                ></ApplyNowCTAV3>
              </div>
            </>
          }
        ></LaunchSteps>
        <LaunchCard></LaunchCard>
        <LaunchExternship
          postContent={
            <>
              <div className="mt-6 flex justify-center sm:justify-start">
                <ApplyNowCTAV3
                  as={<LaunchCTA></LaunchCTA>}
                  height="60px"
                  type={program.PrimaryProgramID}
                  meId={program.PrimaryProgramID}
                  innerText="Apply Now"
                  link={program.typeform}
                  location="EXTERNSHIP"
                  inputClassName="rounded-l-[5px]"
                  applyNowInsideClassName="bg-launch-green-100 hover:shadow-none py-3 rounded-r-[5px]"
                  openEvent="FREE_ME_START_NOW_CLICKED"
                  closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                ></ApplyNowCTAV3>
              </div>
            </>
          }
        ></LaunchExternship>
        <LaunchStructure
          postContent={
            <>
              <div className="mt-16 flex justify-center ">
                <ApplyNowCTAV3
                  as={<LaunchCTA></LaunchCTA>}
                  height="60px"
                  type={program.PrimaryProgramID}
                  meId={program.PrimaryProgramID}
                  innerText="Apply Now"
                  link={program.typeform}
                  location="EXTERNSHIP_STRUCTURE"
                  inputClassName="rounded-l-[5px]"
                  applyNowInsideClassName="bg-launch-green-100 hover:shadow-none py-3 rounded-r-[5px]"
                  openEvent="FREE_ME_START_NOW_CLICKED"
                  closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                ></ApplyNowCTAV3>
              </div>
            </>
          }
        ></LaunchStructure>
        <LaunchExternshipProjects
          postContent={
            <>
              <div className="mt-10 flex justify-center ">
                <ApplyNowCTAV3
                  as={<LaunchCTA></LaunchCTA>}
                  height="60px"
                  type={program.PrimaryProgramID}
                  meId={program.PrimaryProgramID}
                  innerText="Apply Now"
                  link={program.typeform}
                  location="PAST_PROJECTS"
                  inputClassName="rounded-l-[5px]"
                  applyNowInsideClassName="bg-launch-green-100 hover:shadow-none py-3 rounded-r-[5px]"
                  openEvent="FREE_ME_START_NOW_CLICKED"
                  closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                ></ApplyNowCTAV3>
              </div>
            </>
          }
        ></LaunchExternshipProjects>
        <LaunchRecruiter
          postContent={
            <>
              <div className="mt-10 flex justify-center ">
                <ApplyNowCTAV3
                  as={<LaunchCTA></LaunchCTA>}
                  height="60px"
                  type={program.PrimaryProgramID}
                  meId={program.PrimaryProgramID}
                  innerText="Apply Now"
                  link={program.typeform}
                  location="PERSONALISED_PROFILE"
                  inputClassName="rounded-l-[5px]"
                  applyNowInsideClassName="bg-launch-green-100 hover:shadow-none py-3 rounded-r-[5px]"
                  openEvent="FREE_ME_START_NOW_CLICKED"
                  closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                ></ApplyNowCTAV3>
              </div>
            </>
          }
        ></LaunchRecruiter>
        <LaunchTestimonial
          testimonials={Testimonials}
          companyImagesData={CompanyImagesData}
          testimonialImagesData={TestimonialImagesData}
          postContent={
            <>
              <div className="flex justify-center ">
                <ApplyNowCTAV3
                  as={<LaunchCTA></LaunchCTA>}
                  height="60px"
                  type={program.PrimaryProgramID}
                  meId={program.PrimaryProgramID}
                  innerText="Apply Now"
                  link={program.typeform}
                  location="TESTIMONIAL"
                  inputClassName="rounded-l-[5px]"
                  applyNowInsideClassName="bg-launch-green-100 hover:shadow-none py-3 rounded-r-[5px]"
                  openEvent="FREE_ME_START_NOW_CLICKED"
                  closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                ></ApplyNowCTAV3>
              </div>
            </>
          }
        />
        <RoundedLayout
          roundedBg="bg-launch-blue-400"
          className="relative z-[2] bg-white text-black"
          top
        >
          <LaunchFAQ
            postContent={
              <>
                <div className="mt-10 flex justify-center ">
                  <ApplyNowCTAV3
                    as={<LaunchCTA></LaunchCTA>}
                    height="60px"
                    type={program.PrimaryProgramID}
                    meId={program.PrimaryProgramID}
                    innerText="Apply Now"
                    link={program.typeform}
                    location="FAQ"
                    inputClassName="rounded-l-[5px]"
                    applyNowInsideClassName="bg-launch-green-100 hover:shadow-none py-3 rounded-r-[5px]"
                    openEvent="FREE_ME_START_NOW_CLICKED"
                    closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                  ></ApplyNowCTAV3>
                </div>
              </>
            }
            preContent={
              <SectionLayout
                heading={
                  <>
                    <h2 className="mb-20  text-white sm:mb-10">
                      Feel Unprepared for <br className="block sm:hidden"></br>
                      Crio Launch?
                      <br /> Get Ready with our Premium Learning Programs!
                    </h2>
                  </>
                }
                className="relative -mt-[350px] flex flex-col items-center justify-center "
              >
                <div className="mx-4 max-w-[1100px] md:mx-0">
                  <FellowshipProgramCard
                    program={EPrograms[EProgramIDs.FELLOWSHIP]}
                    strapiProgram={queryData.allStrapiPrograms.nodes.find(
                      (ele) => ele.PrimaryProgramID == EProgramIDs.FELLOWSHIP,
                    )}
                    icon={
                      <StaticImage
                        width={100}
                        loading="lazy"
                        src="../images/v5/ProgramCards/LaunchFellowship.png"
                        placeholder="blurred"
                        alt="Fellowship"
                      />
                    }
                    location="LAUNCH"
                    route={ERoutes.FELLOWSHIP}
                    sale={false}
                    variant={EProgramIDs.LAUNCH}
                  ></FellowshipProgramCard>
                </div>
              </SectionLayout>
            }
            faqs={data.faqs}
          />{' '}
        </RoundedLayout>
        <RoundedLayout roundedBg="bg-white" className="bg-white text-black" top>
          <LaunchFooter links={links} />
        </RoundedLayout>
      </div>
      {/* <style>{`
    *::-webkit-scrollbar {
      background: ${twConfig.theme.colors['gray']['700']};      
    }
    *::-webkit-scrollbar-thumb {
      background: ${twConfig.theme.colors['gray']['400']};      
    }
    `}</style> */}
    </>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    launchPageYaml(id: { eq: $id }) {
      slug
      SEOTitle
      SEODescription
      PrimaryProgramID
      typeform

      footerLinks {
        title
        url
      }

      faqs {
        question
        answer
      }

      testimonials {
        image
        name
        role
        company
        linkedInUrl
        testimonial
      }
    }

    CompanyImages: allFile(
      filter: { relativePath: { regex: "images/v5/Launch/Companies/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }

    TestimonialImages: allFile(
      filter: { relativePath: { regex: "images/v5/Launch/Testimonials/" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: {}
            width: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }

    ...strapiDataFragment
  }
`;
