import React, { useState } from 'react';
import SectionLayout from '@components/v5/layouts/SectionLayout/index';
import twConfig from '../../../../../utils/tailwind-fullConfig';
import { useInView } from 'react-intersection-observer';
import { RoughNotation } from 'react-rough-notation';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ClientOnly } from '@components/extra/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Bullet from '../../../../images/v5/Launch/Bullet.svg';
import Ellipse from '../../../../images/v5/Launch/Ellipse.svg';

export default function LaunchStructure(props) {
  return (
    <SectionLayout
      className=" bg-launch-blue-400 text-launch-neutral-100"
      heading={<Heading></Heading>}
      description={
        <div className="text-launch-neutral-100">
          Clear the coding assessment to kickstart your journey to becoming a
          Crio Launch developer.
        </div>
      }
      {...props}
    >
      <div className="container">
        <div className="mb-6">
          <CustomAccordion
            question={'Warm up'}
            duration="2 Weeks"
            badge="Stage 1"
            open={true}
          >
            <p className="mb-2 flex items-start gap-x-4">
              <Bullet className="mt-2"></Bullet> Complete a series of developer
              activities ranging across a variety of software{' '}
              <br className="hidden sm:block"></br>
              development concepts and tech stacks.
            </p>
            <p className="flex items-start gap-x-4 pb-4">
              <Bullet className="mt-2"></Bullet> Build a mini-project (project
              details revealed once selected) to showcase your depth of{' '}
              <br className="hidden sm:block"></br>
              understanding of foundational dev concepts
            </p>
          </CustomAccordion>
        </div>
        <div>
          <CustomAccordion
            question={'Externship project'}
            duration="6 Weeks"
            badge="Stage 2"
            open={false}
          >
            <p className="mb-4">
              Build out a full-fledged software product (details revealed once
              selected for this stage) based on detailed requirements
            </p>
            <div className="flex flex-wrap gap-4 pb-4">
              <div className="w-full rounded-[20px] bg-launch-blue-800 p-5 text-center sm:w-[calc(30%)]">
                Understand the product <br className="hidden sm:block"></br>
                requirements in detail
              </div>{' '}
              <div className="w-full rounded-[20px]  bg-launch-blue-800 p-5 text-center sm:w-[calc(30%)]">
                Complete the design and <br className="hidden sm:block"></br>
                choose the tech stacks
              </div>{' '}
              <div className="w-full rounded-[20px]  bg-launch-blue-800 p-5 text-center sm:w-[calc(30%)]">
                Implement the REST API backend{' '}
                <br className="hidden sm:block"></br>and front end (as needed)
              </div>{' '}
              <div className="w-full rounded-[20px]   bg-launch-blue-800 p-5 text-center sm:w-[calc(30%)]">
                Complete integration testing and{' '}
                <br className="hidden sm:block"></br>fix all functional bugs
              </div>{' '}
              <div className="w-full rounded-[20px] bg-launch-blue-800 p-5 text-center sm:w-[calc(30%)]">
                Dockerise and <br className="hidden sm:block"></br>deploy to AWS
              </div>
            </div>
          </CustomAccordion>
        </div>
      </div>
    </SectionLayout>
  );
}

const Heading = () => {
  const [ref, inView] = useInView();

  return (
    <div ref={ref}>
      <RoughNotation
        type="underline"
        show={inView}
        color={twConfig.theme.colors['launch']['green']['100']}
        multiline={true}
        animationDuration={1500}
        strokeWidth={6}
      >
        <span className="text-launch-green-100">Crio Launch</span>
      </RoughNotation>{' '}
      Externship Structure
    </div>
  );
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `none`,

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    height: props.isExpanded ? '100%' : 'auto',
    borderRadius: '50px !important',
    overflow: 'hidden',
    background: twConfig.theme.colors['launch']['blue']['800'],
    border: 'none !important',
  }),
  content: {
    margin: '8px 0px',
    color: twConfig.theme.colors['launch']['neutral']['100'],
    border: 'none !important',
  },
}));

const CustomAccordion = ({ question, badge, open, duration, children }) => {
  const [expanded, setExpanded] = useState(open || false);
  const classes = useStyles({ isExpanded: expanded });

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  };

  return (
    <ClientOnly>
      <Accordion
        disableGutters
        expanded={expanded}
        elevation={0}
        onChange={handleChange}
        classes={{ root: classes.root }}
      >
        <AccordionSummary
          expandIcon={
            <div className=" my-0 flex h-[24px] w-[24px]  items-center justify-center rounded-full bg-launch-blue-100 text-launch-neutral-500">
              <FontAwesomeIcon
                icon={['fas', 'chevron-right']}
                className={`w-[10px] rotate-90 transition-all`}
              ></FontAwesomeIcon>
            </div>
          }
          classes={{ content: classes.content }}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`w-full overflow-hidden bg-launch-blue-900 p-2 py-4 px-10  text-v5-neutral-200`}
        >
          <div>
            <div>
              {' '}
              <div className="mb-1 w-max min-w-[80px] rounded-[50px] bg-launch-green-100 p-1 px-2  text-center text-xs font-semibold  text-launch-blue-900">
                {badge}
              </div>
            </div>
            <div className="flex items-center whitespace-nowrap font-manrope">
              <h5 className={`mr-3`}>{question}</h5>
              <Ellipse className="mr-3 mt-1"></Ellipse>
              <p className={`mt-1 sm:mt-0`}>{duration}</p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="bg-launch-blue-900 px-10 pt-0 pb-4 font-rubik  leading-relaxed text-launch-neutral-200">
          <div className="">{children}</div>
        </AccordionDetails>
      </Accordion>
    </ClientOnly>
  );
};
