import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { launchSocialLinks } from '@components/FooterV2/data';

export default function LaunchFooter({ links }) {
  return (
    <div className="min-h-[230px] rounded-t-[60px] bg-launch-blue-400 py-20 sm:rounded-t-[100px] md:pt-10 md:pb-28">
      <div className="flex flex-col px-12 md:px-28">
        <div className="flex flex-col justify-between md:flex-row">
          <div className="mb-6 w-[190px] md:mb-0">
            <StaticImage
              loading="eager"
              src="../../../../images/v5/Launch/Crio-logo.png"
              placeholder="none"
              width={200}
              alt="Crio Launch"
            />
          </div>
          <ul className="flex list-none flex-col justify-between gap-2 pl-0 text-white sm:mb-6 md:flex-row md:gap-6">
            {links.map((link) => (
              <li className="last:mb-6 last:md:mb-0" key={link.title}>
                <a href={link.url}>{link.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="h-[0.5px] bg-launch-blue-900 opacity-50"></div>
        <div className="flex  w-full justify-center  py-4 pt-4 text-sm md:justify-between">
          <p className="hidden text-white md:block">
            Copyright © {new Date().getFullYear()} Crio. All rights reserved.
          </p>
          <div className="mb-4 flex gap-4 md:mb-0 md:gap-2">
            {launchSocialLinks.map((social) => {
              return (
                <a
                  href={social.url}
                  key={social.title}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className={`h-[28px] w-[28px]`}>{social.image}</div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
