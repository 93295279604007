import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SectionLayout from '@components/v5/layouts/SectionLayout/index';
import Carousel from './Carousel/index';
import { useInView } from 'react-intersection-observer';
import { RoughNotation } from 'react-rough-notation';
import twConfig from '../../../../../utils/tailwind-fullConfig';

const LaunchHiringCompanies = React.memo(function LaunchHiringCompanies(props) {
  return (
    <SectionLayout className="bg-launch-blue-400">
      <div className="container relative ">
        <div className=" z-20 -mt-32  w-full  items-center justify-center    rounded-[30px] bg-launch-neutral-300 p-5 sm:rounded-[100px] sm:py-0">
          <SectionLayout
            heading={<Heading></Heading>}
            description={
              <p className="font-normal text-launch-neutral-400">
                500+ openings with CTCs ranging from{' '}
                <strong className="font-bold">12 to 40 LPA</strong> for you to{' '}
                <strong className="font-bold text-black">get referred</strong>
              </p>
            }
            {...props}
          >
            <div className="min-h-[280px]">
              <Carousel data={data} />
            </div>
          </SectionLayout>
        </div>
      </div>
    </SectionLayout>
  );
});

const Heading = () => {
  const [ref, inView] = useInView();

  return (
    <div ref={ref}>
      220+ Product Based Companies <br className="hidden sm:block"></br>Hire{' '}
      <RoughNotation
        type="underline"
        show={true}
        color={twConfig.theme.colors['launch']['blue']['400']}
        multiline={true}
        animationDuration={1500}
        strokeWidth={6}
      >
        <span className="text-launch-blue-400">Crio Launch</span>
      </RoughNotation>{' '}
      Developers
    </div>
  );
};

export default LaunchHiringCompanies;

const data = [
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/accenture.png"
        placeholder="blurred"
        alt="Accenture"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Amazon.png"
        placeholder="blurred"
        alt="Amazon"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Arcesium.png"
        placeholder="blurred"
        alt="Arcesium"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Ather.png"
        placeholder="blurred"
        alt="Ather"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/BharatPe.png"
        placeholder="blurred"
        alt="BharatPe"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Briq.png"
        placeholder="blurred"
        alt="Briq"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Capillary.png"
        placeholder="blurred"
        alt="Capillary"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Cars24.png"
        placeholder="blurred"
        alt="Cars24"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/CRED.png"
        placeholder="blurred"
        alt="CRED"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Dailyhunt.png"
        placeholder="blurred"
        alt="Dailyhunt"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/DE Shaw.png"
        placeholder="blurred"
        alt="DE Shaw"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Deloitte.png"
        placeholder="blurred"
        alt="Deloitte"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Fampay.png"
        placeholder="blurred"
        alt="Fampay"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Flipkart.png"
        placeholder="blurred"
        alt="Flipkart"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Grab.png"
        placeholder="blurred"
        alt="Grab"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Grofers.png"
        placeholder="blurred"
        alt="Grofers"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Groww.png"
        placeholder="blurred"
        alt="Groww"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/IBM.png"
        placeholder="blurred"
        alt="IBM"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Jumbotail.png"
        placeholder="blurred"
        alt="Jumbotail"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Juspay.png"
        placeholder="blurred"
        alt="Juspay"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Lendingkart.png"
        placeholder="blurred"
        alt="Lendingkart"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Meesho.png"
        placeholder="blurred"
        alt="Meesho"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Microsoft.png"
        placeholder="blurred"
        alt="Microsoft"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Navi.png"
        placeholder="blurred"
        alt="Navi"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Ninjacart.png"
        placeholder="blurred"
        alt="Ninjacart"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/NoBroker.png"
        placeholder="blurred"
        alt="NoBroker"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Ola.png"
        placeholder="blurred"
        alt="Ola"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Oracle.png"
        placeholder="blurred"
        alt="Oracle"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/PayTm.png"
        placeholder="blurred"
        alt="PayTm"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/PharmEasy.png"
        placeholder="blurred"
        alt="PharmEasy"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Postman.png"
        placeholder="blurred"
        alt="Postman"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/PwC.png"
        placeholder="blurred"
        alt="PwC"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/RupeeK.png"
        placeholder="blurred"
        alt="Rupeek"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Salesforce.png"
        placeholder="blurred"
        alt="Salesforce"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Shiprocket.png"
        placeholder="blurred"
        alt="Shiprocket"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Slice.png"
        placeholder="blurred"
        alt="Slice"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Superset.png"
        placeholder="blurred"
        alt="Superset"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Tekion.png"
        placeholder="blurred"
        alt="Tekion"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Tracxn.png"
        placeholder="blurred"
        alt="Tracxn"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Ula.png"
        placeholder="blurred"
        alt="Ula"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/upGrad.png"
        placeholder="blurred"
        alt="upGrad"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Urban Company.png"
        placeholder="blurred"
        alt="Urban Company"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Vicara.png"
        placeholder="blurred"
        alt="Vicara"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/vmware.png"
        placeholder="blurred"
        alt="vmware"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Vouch.png"
        placeholder="blurred"
        alt="Vouch"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Walmart.png"
        placeholder="blurred"
        alt="Walmart"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/WebEngage.png"
        placeholder="blurred"
        alt="WebEngage"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Yellow Class.png"
        placeholder="blurred"
        alt="Yellow Class"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
  {
    image: (
      <StaticImage
        loading="eager"
        src="../../../../images/v5/Launch/Company Logos/Hiring Companies/Zoho.png"
        placeholder="blurred"
        alt="Zoho"
        width={120}
        imgStyle={{ objectFit: 'contain' }}
        className=" max-h-[60px] max-w-[90px] sm:max-w-none"
      />
    ),
  },
];
