import React from 'react';
import Grid from '../../../../images/v5/Launch/GridPattern.svg';
import MobileGrid from '../../../../images/v5/Launch/Mobile-Grid.svg';
import Bullet from '../../../../images/v5/Launch/Bullet.svg';
import { StaticImage } from 'gatsby-plugin-image';
import LaunchNavigation from '../LaunchNavigation/index';

export default function LaunchHero({ postContent }) {
  return (
    <div className="  relative overflow-hidden bg-launch-blue-500  pb-52 text-launch-neutral-100  sm:pb-0 ">
      <div className=" pb-20 sm:-mb-40">
        <div className="absolute top-0 right-0  z-0 w-screen  opacity-25 ">
          <Grid height="100%" width="100%" className="hidden sm:block"></Grid>
          <MobileGrid
            height="100%"
            width="100%"
            className="sm:hidden "
          ></MobileGrid>
        </div>
        <div className="z-100 relative">
          <LaunchNavigation></LaunchNavigation>
        </div>
        <div className="container flex  flex-wrap gap-4 pt-4  font-manrope sm:flex-nowrap md:pt-16">
          <div className=" z-10 w-full">
            <h6 className="mb-2 ">
              India’s{' '}
              <span className="text-launch-green-100">
                largest tech externship opportunity
              </span>{' '}
              for developers
            </h6>
            <h1 className="mb-4 font-black capitalize leading-tight sm:text-[54px]">
              Get hired into the <br className="hidden sm:block"></br> Best
              Product Based <br className="hidden sm:block"></br>Companies
            </h1>
            <div className="mb-8 flex flex-col gap-y-2 font-rubik">
              <p className="flex items-center gap-x-2">
                <Bullet></Bullet>{' '}
                <strong className="-mr-1 font-bold">Build</strong>
                externship projects
              </p>
              <p className="flex items-center gap-x-2">
                <Bullet></Bullet>{' '}
                <strong className="-mr-1 font-bold">Prove</strong> your
                developer skills
              </p>{' '}
              <p className="flex items-center gap-x-2">
                <Bullet></Bullet>{' '}
                <strong className=" -mr-1 font-bold">Get hired</strong> into
                product based companies!
              </p>
            </div>
            {postContent}
          </div>

          <div className=" sm:min-w-[600px] ">
            <StaticImage
              loading="eager"
              src="../../../../images/v5/Launch/Hero.png"
              placeholder="none"
              alt="Hero Image"
              width={800}
              imgStyle={{ objectFit: 'contain' }}
              className="hidden w-full sm:-mt-20 sm:block"
            />
          </div>
          <div className="absolute right-0 bottom-40 z-0 max-h-[300px]">
            <StaticImage
              loading="eager"
              src="../../../../images/v5/Launch/Mobile-Hero.png"
              placeholder="none"
              alt="Hero Image"
              width={300}
              imgStyle={{ objectFit: 'contain' }}
              className="  w-full sm:hidden "
            />
          </div>
        </div>
      </div>
      {/* <img
        className="w-full"
        src={require(`../../../../images/v5/Launch/GridPattern.svg`).default}
      ></img> */}
    </div>
  );
}
