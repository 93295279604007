import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { ERoutes, ESocialLinks } from '@src/constants/routes/index';
import { EProgramIDs, EPrograms } from '@src/constants/data/programs/index';

export const email = {
  customer_queries_header: 'Customer Queries',
  customer_queries_title: 'support@criodo.com',
  general_queries_header: 'General Enquiries',
  general_queries_title: 'ping@criodo.com',
  phone_number: '063665 28148',
  customer_queries_email_url: ERoutes.CUSTOMER_QUERIES_EMAIL,
  general_queries_email_url: ERoutes.GENERAL_ENQUIRIES_EMAIL,
};

export const programs = [
  {
    title: EPrograms[EProgramIDs.BACKEND_DEVELOPER_EXPERIENCE].title,
    url: ERoutes.BACKEND,
  },
  {
    title: EPrograms[EProgramIDs.FULL_STACK].title,
    url: ERoutes.FULL_STACK,
  },
  {
    title: 'Web Developer Essentials Track',
    url: ERoutes.FREE_TRIAL,
  },
];

export const communityPrograms = [
  {
    title: '#IBelieveInDoing Challenge',
    url: ERoutes.IBD,
  },
  {
    title: 'Crio Winter of Doing',
    url: ERoutes.CWOD,
  },
  { title: 'Crio Project Hub', url: ERoutes.PROJECTS },
];

export const careerPrograms = [
  {
    title: EPrograms[EProgramIDs.FELLOWSHIP].title,
    url: ERoutes.FELLOWSHIP,
  },
  {
    title: EPrograms[EProgramIDs.QA_AUTOMATION_TESTING].title,
    url: ERoutes.QA_AUTOMATION_TESTING,
  },
  {
    title: EPrograms[EProgramIDs.DATA_SCIENCE].title,
    url: ERoutes.DATA_SCIENCE,
  },
  {
    title: EPrograms[EProgramIDs.SYSTEM_DESIGN].title,
    url: ERoutes.SYSTEM_DESIGN,
  },
];

export const crioLinks = [
  { title: 'Placement Report', url: ERoutes.PLACEMENTS },
  { title: 'Success Stories', url: ERoutes.SUCCESS_STORIES },
  { title: 'About', url: ERoutes.ABOUT },
  { title: 'Blog', url: ERoutes.BLOG },
  {
    title: 'Terms of Use',
    url: ERoutes.TERMS_OF_USE,
  },
  { title: 'Privacy Policy', url: ERoutes.PRIVACY_POLICY },
];

export const business = [
  { title: 'Hire from Crio', url: ERoutes.RECRUIT },
  { title: 'Crio Onboard', url: ERoutes.ONBOARD },
];

export const socialLinks = [
  {
    title: 'LinkedIn',
    image: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/Linkedin.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: ESocialLinks.LINKEDIN,
  },
  {
    title: 'Youtube',
    image: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/Youtube.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: ESocialLinks.YOUTUBE,
  },
  {
    title: 'Instagram',
    image: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/Instagram.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: ESocialLinks.INSTAGRAM,
  },
  {
    title: 'Twitter',
    image: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/Twitter.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: ESocialLinks.TWITTER,
  },
  {
    title: 'Facebook',
    image: (
      <StaticImage
        loading="lazy"
        src="../../images/placements/Icons/Facebook.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: ESocialLinks.FACEBOOK,
  },
];

// LAUNCH FOOTER DATA

export const launchSocialLinks = [
  {
    title: 'LinkedIn',
    image: (
      <StaticImage
        loading="lazy"
        src="../../images/v5/Launch/Social/linkedin.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: ESocialLinks.LINKEDIN,
  },
  {
    title: 'Youtube',
    image: (
      <StaticImage
        loading="lazy"
        src="../../images/v5/Launch/Social/youtube.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: ESocialLinks.YOUTUBE,
  },
  {
    title: 'Instagram',
    image: (
      <StaticImage
        loading="lazy"
        src="../../images/v5/Launch/Social/instagram.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: ESocialLinks.INSTAGRAM,
  },
  {
    title: 'Twitter',
    image: (
      <StaticImage
        loading="lazy"
        src="../../images/v5/Launch/Social/twitter.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: ESocialLinks.TWITTER,
  },
  {
    title: 'Facebook',
    image: (
      <StaticImage
        loading="lazy"
        src="../../images/v5/Launch/Social/facebook.png"
        placeholder="blurred"
        alt="Crio.Do"
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: ESocialLinks.FACEBOOK,
  },
];
