import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Grid from '../../../../images/v5/Launch/GridPattern.svg';

export default function LaunchNavigation() {
  const [open, setOpen] = React.useState(false);

  const links = [
    { title: 'How To Join', url: '#how-does-it-work' },
    { title: 'Externship', url: '#what-is-an-externship' },
    { title: 'What You Get', url: '#personalised-profile' },
    { title: 'FAQs', url: '#faqs' },
    {
      title: 'Crio.Do',
      url: '/?utm_source=launch&utm_medium=header&utm_campaign=launch',
    },
  ];

  return open ? (
    <div className="fixed top-0 left-0 z-40  flex h-screen w-screen flex-col bg-launch-blue-800 ">
      <div className="relative z-50 flex min-h-[50%] flex-col items-center  justify-center bg-white py-12">
        <div
          className="w-[17px]"
          onClick={() => {
            setOpen(false);
          }}
        >
          {' '}
          <StaticImage
            loading="lazy"
            src="../../../../images/v5/Launch/CrossIcon.png"
            placeholder="blurred"
            width={100}
            alt="Crio logo"
          />
        </div>

        <ul className="flex list-none flex-col justify-between gap-4 py-8 pl-0 text-center font-rubik text-xl font-bold text-launch-blue-300">
          {links.map((link) => {
            if (link.url.includes('#'))
              return (
                <li
                  className=""
                  key={link.title}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <a href={link.url}>{link.title}</a>
                </li>
              );
            else return <Link to={link.url}>{link.title}</Link>;
          })}
        </ul>

        <div
          className="w-[200px]"
          onClick={() => {
            setOpen(true);
          }}
        >
          <StaticImage
            loading="lazy"
            src="../../../../images/v5/Launch/CrioLogo-Black.png"
            placeholder="none"
            width={300}
            alt="Crio logo"
          />
        </div>
      </div>
      <Grid
        height="100%"
        width="100%"
        className=" scale-[3] transform opacity-75 sm:hidden "
      ></Grid>
    </div>
  ) : (
    <div className="container z-40 mx-auto flex h-[80px] items-center justify-between pt-4 md:pt-8">
      <div className="w-[150px] md:w-[190px]">
        <StaticImage
          loading="lazy"
          src="../../../../images/v5/Launch/Crio-logo.png"
          placeholder="none"
          width={400}
          alt="Crio logo"
        />
      </div>
      <ul className="hidden list-none flex-col justify-between gap-2 pl-0 font-manrope font-bold text-white sm:mb-6 md:flex md:flex-row md:gap-10">
        {links.map((link) => {
          if (link.url.includes('#'))
            return (
              <li
                className="last:mb-6 last:md:mb-0"
                key={link.title}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <a href={link.url}>{link.title}</a>
              </li>
            );
          else
            return (
              <Link to={link.url} className="last:mb-6 last:md:mb-0">
                {link.title}
              </Link>
            );
        })}
      </ul>
      <div
        className="block w-[17px] md:hidden"
        onClick={() => {
          setOpen(true);
        }}
      >
        {' '}
        <StaticImage
          loading="lazy"
          src="../../../../images/v5/Launch/BurgerIcon.png"
          placeholder="blurred"
          width={100}
          alt="Crio logo"
        />
      </div>
    </div>
  );
}
