import React from 'react';
import SectionLayout from '@components/v5/layouts/SectionLayout/index';
import twConfig from '../../../../../utils/tailwind-fullConfig';
import { StaticImage } from 'gatsby-plugin-image';
import useResizer from '@components/extra/useResizer';

export default function LaunchSteps(props) {
  const isMobile = useResizer(640);

  const steps = [
    {
      title: (
        <>
          Take an <br className="hidden sm:block"></br>Assessment
        </>
      ),
      description:
        'Complete our application and participate in our coding assessment to be eligible for the externship project.',
      image: (
        <StaticImage
          loading="lazy"
          src="../../../../images/v5/Launch/Steps/One.png"
          placeholder="blurred"
          alt="Step 1"
          width={400}
        />
      ),
      color: twConfig.theme.colors['launch']['blue']['500'],
    },
    {
      title: <>Build Externship Projects</>,
      description:
        'Build our 8 week externship project to showcase your skills.',
      image: (
        <StaticImage
          loading="lazy"
          src="../../../../images/v5/Launch/Steps/Two.png"
          placeholder="blurred"
          alt="Step 1"
          width={400}
        />
      ),
      color: twConfig.theme.colors['launch']['blue']['600'],
    },
    {
      title: <>Get your Project Reviewed</>,
      description: (
        <>
          Get your project work reviewed by experienced developers and become a{' '}
          <span className="font-bold text-launch-green-100">Crio Launch</span>{' '}
          developer with a personalised profile.
        </>
      ),
      image: (
        <StaticImage
          loading="lazy"
          src="../../../../images/v5/Launch/Steps/Three.png"
          placeholder="blurred"
          alt="Step 1"
          width={400}
        />
      ),
      color: twConfig.theme.colors['launch']['blue']['700'],
    },
    {
      title: (
        <>
          Interview & <br className="hidden sm:block"></br>Get Hired
        </>
      ),
      description:
        'Get interviewed by the best product-based companies in India through Crio.',
      image: (
        <StaticImage
          loading="lazy"
          src="../../../../images/v5/Launch/Steps/Four.png"
          placeholder="blurred"
          alt="Step 1"
          width={400}
        />
      ),
      color: twConfig.theme.colors['launch']['blue']['800'],
    },
  ];
  return (
    <>
      <SectionLayout
        {...props}
        heading={
          <>
            How does it work?
            <span id="how-does-it-work"></span>
          </>
        }
        className="bg-launch-blue-400 text-launch-neutral-100"
      >
        <div className=" launch-steps relative mt-10">
          <div className="container">
            <div className="relative z-10 flex w-full flex-wrap sm:flex-nowrap">
              {steps.map((ele, idx) => (
                <div
                  className={`w-full  p-10 sm:min-w-[350px]  sm:rounded-[50px] sm:pr-[120px] sm:pl-10 ${
                    idx !== 0 ? 'sm:-ml-[100px]' : ''
                  } ${idx == 0 ? 'rounded-t-[50px]' : ''} ${
                    idx == steps.length - 1 ? 'rounded-b-[50px]' : ''
                  }`}
                  style={{ background: ele.color }}
                  key={idx}
                >
                  <div className=" w-full max-w-[300px] ">
                    <div className="mb-4 w-[100px]">{ele.image}</div>
                    <h5 className="mb-2 font-manrope leading-tight">
                      {ele.title}
                    </h5>
                    <p className="font-light leading-relaxed">
                      {ele.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </SectionLayout>
      <style jsx>{`
        .launch-steps {
          background: linear-gradient(
            to right,
            ${twConfig.theme.colors['launch']['blue']['400']} 50%,
            ${twConfig.theme.colors['launch']['blue']['800']} 50%
          );
        }
        @media (max-width: 640px) {
          .launch-steps {
            background: none;
          }
        }
      `}</style>
    </>
  );
}
